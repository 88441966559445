<template>
  <v-row>
    <template v-if="!!$slots.left">
      <template v-if="$vuetify.breakpoint.mdAndDown">
        <v-navigation-drawer v-model="drawer" app 
          width="350px"> 
          <slot name="left"></slot>
        </v-navigation-drawer> 

        <v-btn
          color="primary"
          fixed
          :style="{ bottom: !!$slots.right?'80px':'10px' }"
          bottom
          right
          fab
          @click="() => drawer = !drawer"
        >
          <v-icon>{{ leftPanelIcon }}</v-icon>
        </v-btn>
      </template>
      
      <v-col cols="12" sm="12" md="12" lg="3" xl="2" v-else>
        <slot name="left"></slot>
      </v-col>
    </template>

    <template v-if="!!$slots.right">
      <v-col  cols="12" sm="12" md="12" :lg="lgCols" :xl="xlCols">
        <slot name="center"></slot>
      </v-col>
      
      <n-bottom-sheet ref="swipeableBottomSheet" default-state="half" close-icon="mdi-cart" v-if="$vuetify.breakpoint.mdAndDown">
        <v-layout>
          <v-row>
            <v-col cols="12" md="12" lg="12" xl="12">
              <slot name="right"></slot>
            </v-col>
          </v-row>
        </v-layout>
      </n-bottom-sheet>
      
      <v-col class="" cols="12" sm="12" md="12" lg="4" xl="4" v-else>
        <v-card class="shadow-sm" width="100%">
          <v-card-text>
            <slot name="right"></slot>
          </v-card-text>
        </v-card>
      </v-col>
    </template>

    <template v-else>
      <v-col cols="12" sm="12" md="12" lg="9" xl="10">
        <slot name="center"></slot>
      </v-col>
    </template>
  </v-row>
</template>

<script>
import NBottomSheet from './NBottomSheet.vue';

export default {
  components: {
    NBottomSheet
  },
  props: {
    leftPanelIcon: {
      type: String,
      default: 'mdi-filter'
    }
  },
  computed: {
    lgCols(){
      let tmp = 12;

      if(!!this.$slots.left){
        tmp -= 3;
      }

      if(!!this.$slots.right){
        tmp -= 4;
      }

      return tmp;
    },
    xlCols(){
      let tmp = 12;

      if(!!this.$slots.left){
        tmp -= 2;
      }

      if(!!this.$slots.right){
        tmp -= 4;
      }

      return tmp;
    }
  },
  data(){
    return{
      drawer: false
    }
  }
}
</script>