<template>
  <div style="width: 100%">
    <v-toolbar flat dense class="mb-4 transparent">
      <div class="font-weight-bold text-uppercase">{{ $t("Checkout.youOrder") }}</div>

      <v-spacer></v-spacer>

      <div v-if="enableClearLink">
        <a @click="() => $emit('clear')" class="caption">{{ $t('Checkout.clearCart') }}</a>
      </div>
    </v-toolbar>

    <template v-for="(item, index) in items">
      <v-row :key="`r-${index}`" align="center" justify="center">

        <v-col cols="3">
          <v-text-field v-model="item.cantidadCaja" type="number" :min="1" dense hide-details outlined
            :disabled="disabled || item.esAgendable == 'Y'" @keydown="(evt) => {
                if (['e', 'E', '+', '-'].includes(evt.key)) {
                  evt.preventDefault();
                }
              }
              " @change="(v) => {
                if (v == undefined || v == null || v == '') {
                  item.cantidadCaja = 0;
                }
              }
              " v-if="editable"></v-text-field>
          <template v-else>
            <v-btn class="mr-3" icon @click="() => $emit('delete', item)" v-if="enableDelete">
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
            <span class="font-weight-bold">{{ item.cantidadCaja }}</span> x
          </template>
        </v-col>

        <v-col cols="6">
          <div>{{ item.producto }}</div>
          <div class="caption primary--text"
            v-if="item.promocion != null && item.promocion != undefined && item.promocion != ''">{{ item.promocion }}
          </div>
          <div class="caption primary--text"
            v-if="item.fechaApt != null && item.fechaApt != undefined && item.fechaApt != ''">
            <v-icon x-small color="primary" class="pr-2">mdi-calendar</v-icon>{{ item.fechaApt }}
          </div>
          <div v-if="enableDeleteLink"><a @click="() => $emit('delete', item)" class="caption">{{
            $t('Checkout.removeItem')
              }}</a></div>
        </v-col>

        <v-col cols="3" style="text-align: right">
          <div>$ {{ (item[unitPriceKey] || 0).toFixed(2) }}</div>
          <div class="caption primary--text"
            v-if="item.promocion != null && item.promocion != undefined && item.promocion != ''">$ - {{ (item.descuento
              ||
            0).toFixed(2) }}</div>
        </v-col>

      </v-row>

      <v-divider :key="'s-' + index" class="my-2" />
    </template>

    <v-list color="transparent">
      <v-list-item>
        <v-list-item-content>{{ $t("Checkout.subtotal") }}:</v-list-item-content>
        <v-list-item-action>$ {{ (subtotal || 0).toFixed(2) }}</v-list-item-action>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>{{ $t("Checkout.discount") }}:</v-list-item-content>
        <v-list-item-action>$ {{ (descuento || 0).toFixed(2) }}</v-list-item-action>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>{{ $t("Checkout.tax") }}:</v-list-item-content>
        <v-list-item-action>$ {{ (iva || 0).toFixed(2) }}</v-list-item-action>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>{{ $t("Checkout.ieps") }}:</v-list-item-content>
        <v-list-item-action>$ {{ (ieps || 0).toFixed(2) }}</v-list-item-action>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>{{ $t("Checkout.shipping") }}:</v-list-item-content>
        <v-list-item-action>$ {{ (0).toFixed(2) }}</v-list-item-action>
      </v-list-item>

      <v-divider></v-divider>

      <v-list-item>
        <v-list-item-content>{{ $t("Checkout.total") }}:</v-list-item-content>
        <v-list-item-action>$ {{ (total).toFixed(2) }}</v-list-item-action>
      </v-list-item>
    </v-list>

    <div class="d-flex justify-space-between mt-4">
      <slot name="actions" :count="itemsCount"></slot>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      checkProperty: false
    }
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    discountKey: {
      type: String,
      default: "descuento",
    },
    quantityKey: {
      type: String,
      default: "cantidadCaja",
    },
    iepsKey: {
      type: String,
      default: "montoIeps",
    },
    taxKey: {
      type: String,
      default: "importeIva",
    },
    unitPriceKey: {
      type: String,
      default: "precioUnitario",
    },
    subTotalKey: {
      type: String,
      default: "subtotal"
    },
    enableDelete: {
      type: Boolean,
      default: false,
    },
    enableDeleteLink: {
      type: Boolean,
      default: false,
    },
    enableClearLink: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    itemsCount() {
      return this.items.length;
    },
    ieps() {
      this.checkProperty = this.items.some(o => o.hasOwnProperty('subtotal'));
      if (this.checkProperty == false) {
        return _.sumBy(this.items, (i) => Math.round(i[this.iepsKey] * 100) / 100 * i[this.quantityKey]);
      } else if (this.checkProperty == true) {
        return _.sumBy(this.items, (i) => Math.round(i[this.iepsKey] * 100) / 100);
      }
    },
    iva() {
      this.checkProperty = this.items.some(o => o.hasOwnProperty('subtotal'));
      if (this.checkProperty == false) {
        return _.sumBy(this.items, (i) => Math.round(i[this.taxKey] * 100 ) / 100 * i[this.quantityKey]);
      } else if (this.checkProperty == true) {
        return _.sumBy(this.items, (i) => Math.round(i[this.taxKey] * 100) / 100);
      }
    },
    descuento() {
      return _.sumBy(this.items, (i) => i[this.discountKey]);
    },
    subtotal() {
      this.checkProperty = this.items.some(o => o.hasOwnProperty('subtotal'));
      if (this.checkProperty == false) {
        return _.sumBy(this.items, (i) => Math.round(i[this.unitPriceKey] * 100) / 100 * i[this.quantityKey]);
      } else if (this.checkProperty == true) {
        return _.sumBy(this.items, (i) => Math.round(i[this.subTotalKey] * 100) / 100);
      }
    },
    total() {
      this.checkProperty = this.items.some(o => o.hasOwnProperty('subtotal'));
      if (this.checkProperty == false) {
        let totalCalculado = _.sumBy(this.items, (i) => Math.round(i[this.unitPriceKey] * 100) / 100 * i[this.quantityKey] + Math.round(i[this.taxKey] * 100) / 100 * i[this.quantityKey] + Math.round(i[this.iepsKey] * 100) / 100 * i[this.quantityKey]);
        return totalCalculado = Math.trunc(totalCalculado * 100) / 100;
      } else if (this.checkProperty == true) {
        let totalCalculado = _.sumBy(this.items, (i) => Math.round(i.subtotal * 100) / 100 + Math.round((i.importeIva || 0) * 100)/100 + Math.round((i.montoIeps || 0) * 100) /100 - i.descuento);
        return totalCalculado = Math.trunc(totalCalculado * 100) / 100;
      }
    },
  },
};
</script>